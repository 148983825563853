
export default {
  name: 'HomePageSelectionLinksList',
  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  computed: {
    headText () {
      return this.api?.children
        ?.find(child => child.componentType === 'header')?.children?.find(child => child.componentType === 'h1')?.data?.text ?? ''
    },
  },
}
