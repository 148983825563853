
export default {
  name: 'HomePageSelectionLinks',
  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  computed: {
    items () {
      return this.api?.children?.find(child => child.componentType === 'links_section').children
    },
  },
}
