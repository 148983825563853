
export default {
  name: 'HomePageSelectionLinksItem',

  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  computed: {
    isRelative () {
      const url = this.item?.data?.url || ''

      return url.startsWith('/')
    },

    link () {
      const url = this.item?.data?.url || ''

      if (this.isRelative) {
        return {
          to: this.localePath(url),
        }
      }
      return {
        href: url,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    },
  },
}
